import clsx from "clsx";

import { useRef, useState } from "react";

import CancelButton from "../../UI/CancelButton";
import DeleteButton from "../../UI/DeleteButton";
import LoadingSpinner from "../../UI/LoadingSpinner";
import SaveButton from "../../UI/SaveButton";
import SelectBox from "../../UI/SelectBox";
import TextArea from "../../UI/TextArea";

import RecommendationResources from "../../Dashboard/Search/Recommendations/RecommendationResources";
import Title from "../../Dashboard/Search/Recommendations/Title";

import Actions from "./Actions";

function buildStateName(icon, name) {
  return (
    <span className="flex sm:gap-x-2 text-sm">
      {icon} {name}
    </span>
  );
}

const states = [
  {
    id: 0,
    name: buildStateName("🌱", "Thinking About It"),
    value: "thinking",
  },
  {
    id: 1,
    name: buildStateName("💪", "Working On It"),
    value: "working",
  },
  {
    id: 2,
    name: buildStateName("🎉", "Finished It"),
    value: "finished",
  },
];

const ActionItem = (props) => {
  const { item, onDelete, onSave, isLoading, onCancel } = props;

  const content = item?.content || item?.recommendation?.dynamic_content || item?.recommendation?.content;

  const [showDetails, setShowDetails] = useState(false);
  const [showEditing, setShowEditing] = useState(item.id === -1); // show editing form for new items
  const [isFormValid, setIsFormValid] = useState(false);

  const contentRef = useRef();

  const handleDelete = (e) => {
    e.preventDefault();
    onDelete(item.id);
  };

  const handleSubmit = () => {
    setShowEditing(false);

    if (content === contentRef.current.value) {
      return;
    }

    onSave({ ...item, content: contentRef.current.value });
  };

  const handleUpdateState = (state) => {
    onSave({ ...item, state });
  };

  const handleCancelEditing = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowEditing(false);

    if (!!onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleNotifyChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSave({ ...item, notify: !item.notify });
  };

  return (
    <>
      <li
        className={clsx(
          "relative flex flex-col items-center justify-between transition-colors duration-200",
          showDetails || showEditing
            ? "ring-1 ring-indigo-200 rounded-md shadow-md"
            : "hover:bg-slate-50 hover:rounded-md hover:shadow-sm hover:ring-1 hover:ring-slate-200 "
        )}
      >
        {isLoading && (
          <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <LoadingSpinner small showLoadingText={false} color="text-indigo-600" />
          </div>
        )}
        {showEditing ? (
          <div className="flex flex-col w-full">
            <div className="p-3">
              <TextArea
                placeHolder="Add custom recommendation"
                ref={contentRef}
                rows={3}
                value={content}
                onChange={(value) => setIsFormValid(!!value)}
              />
            </div>
            <div className="px-4 py-4">
              <div className="flex justify-center sm:justify-end space-x-3">
                <span className="hidden sm:block">
                  {onDelete !== undefined && onDelete !== null && <DeleteButton onClick={handleDelete} />}
                </span>
                <CancelButton onClick={handleCancelEditing} />
                <SaveButton onClick={handleSubmit} disabled={!isFormValid} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full p-2 gap-x-2 items-center justify-between">
            <div className="flex flex-col sm:flex-row justify-between w-full">
              <Title
                pillarNames={item.recommendation?.pillars || []}
                content={content}
                references={item?.recommendation?.references || []}
                onInfo={() => setShowDetails((p) => !p)}
              />
              <div>
                <SelectBox
                  optMinW="sm:min-w-48"
                  activeValue={states.find((s) => s.value === item.state) || states[0]}
                  values={states}
                  onChange={(v) => handleUpdateState(v.value)}
                />
              </div>
            </div>
            <Actions
              open={showDetails}
              notify={item.notify}
              onNotifyChange={handleNotifyChange}
              onDelete={handleDelete}
              onEdit={() => setShowEditing(true)}
              onDetails={() => setShowDetails((p) => !p)}
            />
          </div>
        )}
        {showDetails && (
          <RecommendationResources
            recommendationId={item.id}
            reviewedAt={item?.recommendation?.reviewed_at}
          />
        )}
      </li>
    </>
  );
};

export default ActionItem;
