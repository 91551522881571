import clsx from "clsx";

import { useEffect, useRef, useState } from "react";

import { isPhoneValid, isEmailValid, isWebsiteValid } from "../../utils/validators";
import Input from "../UI/Input";
import TextArea from "../UI/TextArea";

const ClinicDetails = (props) => {
  const { clinic, onUpdate } = props;

  const [name, setName] = useState(clinic?.name || "");
  const [address, setAddress] = useState(clinic?.address || "");
  const [phone, setPhone] = useState(clinic?.phone || "");
  const [fax, setFax] = useState(clinic?.fax || "");
  const [email, setEmail] = useState(clinic?.email || "");
  const [website, setWebsite] = useState(clinic?.website || "");
  const [logo, setLogo] = useState(clinic?.logo);

  const [logoFile, setLogoFile] = useState();

  const [nameError, setNameError] = useState(true);
  const [addressError, setAddressError] = useState(true);
  const [phoneError, setPhoneError] = useState(true);
  const [faxError, setFaxError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [websiteError, setWebsiteError] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);

  const logoRef = useRef();

  useEffect(() => {
    setNameError(name.length < 3);
  }, [name]);

  useEffect(() => {
    // TODO validate address using some address validation service
    setAddressError(address.length < 3);
  }, [address]);

  useEffect(() => {
    const res = !isPhoneValid(phone);
    setPhoneError(phone.length < 1 || res);
  }, [phone]);

  useEffect(() => {
    if (fax.length > 0) {
      setFaxError(isPhoneValid(fax));
    } else {
      setFaxError(false);
    }
  }, [fax]);

  useEffect(() => {
    setEmailError(email.length < 1 || !isEmailValid(email));
  }, [email]);

  useEffect(() => {
    if (website.length > 0) {
      setWebsiteError(!isWebsiteValid(website));
    } else {
      setWebsiteError(false);
    }
  }, [website]);

  useEffect(() => {
    setIsFormValid(!nameError && !addressError && !phoneError && !faxError && !emailError && !websiteError);
  }, [nameError, addressError, phoneError, faxError, emailError, websiteError]);

  const onLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setLogoFile(file);

      let reader = new FileReader();
      reader.onload = (e) => {
        setLogo(URL.createObjectURL(file));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setLogo(null);
    setLogoFile(null);
  };

  const handleChangeLogo = () => {
    logoRef.current.click();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    onUpdate({
      name,
      phone,
      fax,
      email,
      website,
      address,
      logo: logoFile,
    });
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <input
                type="file"
                onChange={onLogoChange}
                id="logo-file"
                name="logo-file"
                ref={logoRef}
                className="hidden"
              />
              <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                Clinic Logo
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex items-center">
                  <div className="relative">
                    {logo ? (
                      <img className="h-16 w-16 rounded-full" src={logo} alt={name} />
                    ) : (
                      <span className="inline-block overflow-hidden h-16 w-16 rounded-full bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                            />
                          </svg>
                        </svg>
                      </span>
                    )}
                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                  </div>
                  <button
                    type="button"
                    onClick={handleChangeLogo}
                    className="ml-5 opacity-80 inline-flex items-center rounded-md border border-transparent font-semibold bg-indigo-200 px-3 py-2 text-sm leading-4 text-indigo-800 shadow-sm hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Change
                  </button>
                  <button
                    type="button"
                    onClick={handleRemoveLogo}
                    className="ml-5 opacity-80 inline-flex items-center rounded-md border border-transparent bg-red-400 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>

            <Input
              type="text"
              name="name"
              placeholder="Clinic Name"
              label="Clinic Name"
              value={name}
              isValid={!nameError}
              onChange={(val) => setName(val)}
              errorMessage="Clinic name cannot be blank!"
            />

            <Input
              type="tel"
              name="phone"
              placeholder="555-555-5555"
              label="Clinic Phone Number"
              value={phone}
              isValid={!phoneError}
              onChange={(val) => setPhone(val)}
              errorMessage="Valid phone number is required!"
            />

            <Input
              type="tel"
              name="fax"
              placeholder="555-555-5555"
              label="Clinic Fax Number"
              value={fax}
              isValid={!faxError}
              onChange={(val) => setFax(val)}
              errorMessage="Please provide valid fax number!"
            />

            <Input
              type="email"
              name="email"
              placeholder="info@your-clinic.com"
              label="Clinic Email"
              value={email}
              isValid={!emailError}
              onChange={(val) => setEmail(val)}
              errorMessage="Please provide valid email for your clinic!"
            />

            <Input
              type="url"
              name="website"
              placeholder="https://your-clinic.com"
              label="Clinic Website"
              value={website}
              isValid={!websiteError}
              onChange={(val) => setWebsite(val)}
              errorMessage="Please provide valid website for your clinic!"
            />

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <TextArea
                name="address"
                label="Clinic Address"
                value={address}
                rows={5}
                isValid={!addressError}
                errorMessage="Please provide valid address!"
                required={true}
                onChange={(val) => setAddress(val)}
                maxWLg={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end gap-x-3">
          <button
            type="submit"
            disabled={!isFormValid}
            // className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            className={clsx(
              "ml-3 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm",
              isFormValid
                ? "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                : "bg-blue-400"
            )}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClinicDetails;
