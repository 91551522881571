import clsx from "clsx";

import { useEffect, useRef, useState } from "react";

import { isEmailValid, isPhoneValid } from "../../utils/validators";
import Input from "../UI/Input";

const ClinicUserDetails = (props) => {
  const { user, onClose, onChange } = props;

  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  const [title, setTitle] = useState(user?.title || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phone, setPhone] = useState(user?.phone || "");
  const [photo, setPhoto] = useState(user?.photo || "");

  const [photoFile, setPhotoFile] = useState(null);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const photoRef = useRef();

  useEffect(() => {
    setFirstNameError(firstName.length < 3);
  }, [firstName]);

  useEffect(() => {
    setLastNameError(lastName.length < 3);
  }, [lastName]);

  useEffect(() => {
    if (title.length > 0) {
      setTitleError(title.length < 3);
    } else {
      setTitleError(false);
    }
  }, [title]);

  useEffect(() => {
    setPhoneError(phone.length > 1 && !isPhoneValid(phone));
  }, [phone]);

  useEffect(() => {
    setEmailError(email.length < 2 || !isEmailValid(email));
  }, [email]);

  useEffect(() => {
    setIsFormValid(!firstNameError && !lastNameError && !titleError && !emailError && !phoneError);
  }, [firstNameError, lastNameError, titleError, emailError, phoneError]);

  const handleRemovePhoto = () => {
    setPhoto(null);
    setPhotoFile(undefined);
  };

  const handleChangePhoto = () => {
    photoRef.current.click();
  };

  const onPhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setPhotoFile(file);

      let reader = new FileReader();
      reader.onload = (e) => {
        setPhoto(URL.createObjectURL(file));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    onChange({ id: user?.id, first_name: firstName, last_name: lastName, title, email, phone, photo: photoFile });
  };

  return (
    <div className="divide-y divide-gray-200">
      <div className="pb-6">
        <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
        <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
          <div>
            <div className="-m-1 flex">
              <div className="group inline-flex overflow-hidden rounded-lg border-4 border-white">
                <input
                  type="file"
                  onChange={onPhotoChange}
                  id="photo-file"
                  name="photo-file"
                  ref={photoRef}
                  className="hidden"
                />
                <div className="relative">
                  {photo ? (
                    <img
                      className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                      src={photo}
                      alt={`${firstName} ${lastName}`}
                    />
                  ) : (
                    <span className="inline-block overflow-hidden h-24 w-24 sm:h-40 sm:w-40 lg:h-48 lg:w-48 bg-gray-100">
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}
                  <div className="group-hover:block hidden absolute top-14 left-14">
                    <button
                      onClick={handleChangePhoto}
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent opacity-80 bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Change
                    </button>
                    {photo && (
                      <button
                        onClick={handleRemovePhoto}
                        type="button"
                        className="mt-5 opacity-80 inline-flex items-center rounded-md border border-transparent bg-red-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:ml-6 sm:flex-1">
            <div className="grid grid-flow-col grid-cols-2">
              <div className="flex item-end justify-end">
                <div>
                  <button
                    onClick={onClose}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!isFormValid}
                    type="button"
                    onClick={handleSubmit}
                    className={clsx(
                      "ml-3 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm",
                      isFormValid
                        ? "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        : "bg-blue-400"
                    )}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:px-0 sm:py-0">
          <div className="mx-5 my-5 space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 sm:space-y-5">
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div className="space-y-6 sm:space-y-5">
                  <Input
                    type="text"
                    name="firstName"
                    placeholder="Jane"
                    label="User's First Name (required)"
                    value={firstName}
                    isValid={!firstNameError}
                    onChange={(val) => setFirstName(val)}
                    errorMessage="User's first name cannot be blank!"
                    maxWLg={false}
                  />

                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Doe"
                    label="User's Last Name (required)"
                    value={lastName}
                    isValid={!lastNameError}
                    onChange={(val) => setLastName(val)}
                    errorMessage="User's last name cannot be blank!"
                    maxWLg={false}
                  />

                  <Input
                    type="text"
                    name="title"
                    placeholder="Physician"
                    label="Title"
                    value={title}
                    isValid={!titleError}
                    onChange={(val) => setTitle(val)}
                    errorMessage="User's title has to be at least 2 characters!"
                    maxWLg={false}
                  />

                  <Input
                    type="tel"
                    name="phone"
                    placeholder="555-555-5555"
                    label="User's Phone Number"
                    value={phone}
                    isValid={!phoneError}
                    onChange={(val) => setPhone(val)}
                    errorMessage="Valid phone number is required!"
                    maxWLg={false}
                  />

                  <Input
                    type="email"
                    name="email"
                    placeholder="user@your-clinic.com"
                    label="User's Email (required)"
                    value={email}
                    isValid={!emailError}
                    onChange={(val) => setEmail(val)}
                    errorMessage="Please provide valid email!"
                    maxWLg={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicUserDetails;
