/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";

/*
Expects tabs in following format:
{
    name: <name to show>,
    icon: <icon to show for the tab>,
    value: <numeric value for comparison>
}
*/
const PageSectionTabs = (props) => {
  const { tabs, onChange, curTab } = props;

  const handleClick = (tab) => {
    if (!!tab.href) {
      window.open(tab.href, tab.target || "_blank", "noopener,noreferrer");
    } else {
      onChange(tab);
    }
  };

  return (
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              className={clsx(
                tab.value === curTab.value
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "group cursor-pointer inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
              )}
              onClick={() => handleClick(tab)}
            >
              <tab.icon
                className={clsx(
                  tab.value === curTab.value ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                  "-ml-0.5 mr-2 h-5 w-5"
                )}
              />
              <span>{tab.name}</span>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default PageSectionTabs;
