import { useState } from "react";

import {
  RiArrowDownSFill,
  RiArrowDownSLine,
  RiArrowUpSFill,
  RiArrowUpSLine,
  RiUserAddFill,
  RiUserAddLine,
} from "@remixicon/react";

const Actions = (props) => {
  const { open, onAdd, onInfo, canAdd = true, showAdd = true } = props;

  const [hoverAdd, setHoverAdd] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(false);

  return (
    <div className="flex shrink-0 items-center gap-x-4">
      <span
        onMouseEnter={() => setHoverInfo(true)}
        onMouseLeave={() => setHoverInfo(false)}
        onClick={onInfo}
        className="cursor-pointer text-slate-800 z-30"
      >
        {open ? (
          hoverInfo ? (
            <RiArrowUpSFill className="h-5 w-5" />
          ) : (
            <RiArrowUpSLine className="h-5 w-5" />
          )
        ) : hoverInfo ? (
          <RiArrowDownSFill className="h-5 w-5" />
        ) : (
          <RiArrowDownSLine className="h-5 w-5" />
        )}
      </span>
      {showAdd && (
        <span
          onMouseEnter={() => setHoverAdd(true)}
          onMouseLeave={() => setHoverAdd(false)}
          onClick={onAdd}
          disabled={!canAdd}
          data-tooltip-hidden={canAdd}
          data-tooltip-id="actions-tooltip"
          data-tooltip-content="Upgrade to our premium plan to manage patients"
          className="cursor-pointer text-indigo-800 z-30"
        >
          {hoverAdd ? <RiUserAddFill size={22} /> : <RiUserAddLine size={22} />}
        </span>
      )}
    </div>
  );
};

export default Actions;
