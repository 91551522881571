import clsx from "clsx";

import { useState } from "react";

import { createPortal } from "react-dom";

import AddToPatientDialog from "../AddToPatientDialog";
import RecommendationResources from "./RecommendationResources";
import Title from "./Title";
import Actions from "./Actions";

const RecommendationResult = (props) => {
  const { item: recommendation, showAddToPatient = true } = props;

  const [showInfo, setShowInfo] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const infoClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowInfo((prev) => !prev);
  };

  const addClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowAdd(true);
  };

  return (
    <>
      <li
        className={clsx(
          "relative flex flex-col px-6 py-3",
          showInfo ? "ring-1 ring-indigo-200 rounded-md shadow-md" : "hover:bg-slate-50"
        )}
      >
        <div className="flex justify-between gap-x-6">
          <Title
            pillarNames={recommendation.pillars}
            content={recommendation.dynamic_content || recommendation.content}
            references={recommendation.references}
            onInfo={infoClick}
          />
          <Actions
            open={showInfo}
            onAdd={addClick}
            onInfo={infoClick}
            showAdd={showAddToPatient}
          />
        </div>
        {showInfo && (
          <RecommendationResources recommendationId={recommendation.id} reviewedAt={recommendation.reviewed_at} />
        )}
      </li>
      {showAdd &&
        createPortal(
          <AddToPatientDialog open={showAdd} recommendation={recommendation} onClose={() => setShowAdd(false)} />,
          document.getElementById("dialog")
        )}
    </>
  );
};

export default RecommendationResult;
