import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import { usePostHog } from "posthog-js/react";

import { ApiContext } from "../providers/ApiProvider";

import UserInfoForm from "../components/Registration/UserInfoForm";

const RegisterPage = () => {
  const navigate = useNavigate();

  const api = useContext(ApiContext);

  const posthog = usePostHog();

  const [isLoading, setIsLoading] = useState(false);

  const createSubscription = (data) => {
    setIsLoading(true);

    api.client
      .post(`/registrations`, { ...data })
      .then(() => {
        posthog?.capture("user_registered", {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
        });
        setIsLoading(false);
        navigate("/dashboard");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex min-h-full bg-white justify-center">
      <div className="flex flex-1 flex-col justify-center max-w-3xl px-4 py-12 sm:px-6 lg:flex-none mx-10">
        <div className="flex flex-1 flex-col items-center">
          <img alt="Pro-Patient Tech" src="/images/favicon.png" className="h-14 w-auto" />
        </div>

        <div className="mt-3">
          <div className="flex flex-col items-center">
            <h2 className="mb-3 text-2xl font-bold leading-9 tracking-tight text-slate-900">
              Please Enter Your Details
            </h2>
            <UserInfoForm isLoading={isLoading} onSubmit={createSubscription} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
