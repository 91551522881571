import { Tooltip } from "react-tooltip";
import { useHits } from "react-instantsearch";

import Pagination from "./Pagination";

const SearchResultList = (props) => {
  const { resultComponent: ResultComponent } = props;

  const { onAdd, ...searchProps } = props; // useHits doesn't like onAdd passed into it :|

  const { results } = useHits(searchProps);

  if (results.hits.length === 0) return "";

  return (
    <>
      <>
        <ul className="w-full divide-y divide-slate-100 py-3 px-2">
          {results.hits.map((item, idx) => (
            <ResultComponent key={idx} item={item} onAdd={onAdd} />
          ))}
          <li className="relative flex items-center justify-between border-t border-slate-200 bg-white px-6 py-3">
            <Pagination />
          </li>
        </ul>
        <Tooltip id="actions-tooltip" />
      </>
    </>
  );
};

export default SearchResultList;
