/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const PreviousButton = (props) => {
  const { onClick } = props;

  return (
    <a
      onClick={onClick}
      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
    >
      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
    </a>
  );
};

const NextButton = (props) => {
  const { onClick } = props;

  return (
    <a
      onClick={onClick}
      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
    >
      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
    </a>
  );
};

const PageButton = (props) => {
  const { isCurrent, label, onClick } = props;

  return (
    <a
      onClick={onClick}
      className={clsx(
        "cursor-pointer relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20",
        isCurrent
          ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
      )}
    >
      {label}
    </a>
  );
};

const PaginateMany = (props) => {
  const { onPreviousPage, onNextPage, onPage, page, lastPage } = props;

  if (lastPage - page > 5) {
    const firstPageSet = [page, page + 1, page + 2];
    const lastPageSet = [lastPage - 2, lastPage - 1, lastPage];

    return (
      <>
        <PreviousButton onClick={onPreviousPage} />
        {firstPageSet.map((i) => (
          <PageButton key={i} isCurrent={page === i} label={i} onClick={() => onPage(i)} />
        ))}
        <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
          ...
        </span>
        {lastPageSet.map((i) => (
          <PageButton key={i} isCurrent={page === i} label={i} onClick={() => onPage(i)} />
        ))}
        <NextButton onClick={onNextPage} />
      </>
    );
  } else {
    const pages = [lastPage - 5, lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];

    return (
      <>
        <PreviousButton onClick={onPreviousPage} />
        {pages.map((i) => (
          <PageButton key={i} isCurrent={page === i} label={i} onClick={() => onPage(i)} />
        ))}
        <NextButton onClick={onNextPage} />
      </>
    );
  }
};

const Paginator = (props) => {
  const { totalItems, onGoToPage, page = 1, pageSize = 20, showTotals = true } = props;

  const totalPages = parseInt(totalItems / pageSize) + (totalItems % pageSize > 0 ? 1 : 0);

  const fromItem = (page - 1) * pageSize + 1;
  const toItem = page === totalPages ? totalItems : fromItem + pageSize - 1;

  const handlePageClick = (page) => {
    onGoToPage(page);
  };

  const handlePreviousPage = () => {
    if (page === 1) {
      return;
    }

    onGoToPage(page - 1);
  };

  const handleNextPage = () => {
    if (totalPages === page) {
      return;
    }

    onGoToPage(page + 1);
  };

  if (totalPages < 2) {
    return <></>;
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {showTotals && (
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{fromItem}</span> to <span className="font-medium">{toItem}</span>{" "}
              of <span className="font-medium">{totalItems}</span> results
            </p>
          )}
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            {totalPages > 6 ? (
              <PaginateMany
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
                onPage={handlePageClick}
                page={page}
                lastPage={totalPages}
              />
            ) : (
              <>
                <PreviousButton onClick={handlePreviousPage} />
                {[...Array(totalPages)].map((_, i) => (
                  <PageButton key={i} isCurrent={page === i + 1} label={i + 1} onClick={() => handlePageClick(i + 1)} />
                ))}
                <NextButton onClick={handleNextPage} />
              </>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
