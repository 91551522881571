import clsx from "clsx";

import { usePagination } from "react-instantsearch";

import { RiArrowLeftSLine, RiArrowRightSLine } from "@remixicon/react";

const Pagination = (props) => {
  const { pages, currentRefinement, nbHits, nbPages, isFirstPage, isLastPage, refine } = usePagination({
    padding: 5,
    ...props,
  });

  const goToFirstPage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isFirstPage) return;

    refine(0);
  };

  const goToLastPage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isLastPage) return;

    refine(nbPages - 1);
  };

  const goToPage = (e, page) => {
    e.preventDefault();
    e.stopPropagation();
    refine(page);
  };

  const fromItem = () => {
    return currentRefinement * 20 + 1;
  };

  const toItem = () => {
    return Math.min((currentRefinement + 1) * 20, nbHits);
  };

  return (
    <div className="flex flex-1 items-center justify-between">
      <div>
        <p className="text-sm text-slate-700">
          Showing <span className="font-medium">{fromItem()}</span> to <span className="font-medium">{toItem()}</span>{" "}
          of <span className="font-medium">{nbHits}</span> results
        </p>
      </div>
      <div>
        <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
          <span
            onClick={goToFirstPage}
            className="cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-slate-400 ring-1 ring-inset ring-slate-300 hover:bg-slate-50 focus:z-20 focus:outline-offset-0"
          >
            <RiArrowLeftSLine size={20} />
          </span>
          {pages.map((page) => (
            <span
              key={page}
              onClick={(event) => goToPage(event, page)}
              className={clsx(
                "cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-slate-300 focus:z-20 ",
                page === currentRefinement
                  ? "z-10 bg-indigo-500 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  : "text-slate-900 hover:bg-slate-50 focus:outline-offset-0"
              )}
            >
              {page + 1}
            </span>
          ))}
          <span
            onClick={goToLastPage}
            className="cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-slate-400 ring-1 ring-inset ring-slate-300 hover:bg-slate-50 focus:z-20 focus:outline-offset-0"
          >
            <RiArrowRightSLine size={20} />
          </span>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
